import React from "react";

const logob = require('../../images/logo/logo-b.png');

const Loader = () => {
    return (
        <div className="loading-area">
            <div className="loading-box" />
            <div className="loading-pic">
                <img src={logob.default} alt="Logomarca" width={65} />
            </div>
        </div>
    );
};

export default Loader;
