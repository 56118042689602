import React from 'react';
import { NavLink } from 'react-router-dom';
import Navigation from '../Common/Navigation';
import { AppContext } from '../AppContext';
import { FormattedMessage } from "react-intl";

class Header extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            logoB: require('./../../images/logo/logo-b.png'), 
            logoFull: require('./../../images/logo/logo-full.png'),
            logoFullAlternative: require('./../../images/logo/logo-full-alternative.png')
        };
    }

    state = { isSearchActive: false, isQuoteActive: false };

    handleSearchToggle = () => {
        this.setState({ isSearchActive: !this.state.isSearchActive });
    };

    handleQuoteToggle = () => {
        this.setState({ isQuoteActive: !this.state.isQuoteActive });
    };

    componentDidMount() {
        const handleScroll = () => {
            const offset = window.scrollY;

            const stickyheader = document.querySelector('.sticky-header ');

            // if (offset >= 600) {
            //     stickyheader.classList.add('is-fixed');
            //     stickyheader.classList.add('color-fill');

            // } else {
            //     stickyheader.classList.remove('is-fixed');
            //     stickyheader.classList.remove('color-fill');
            // }
        }

        window.addEventListener('scroll', handleScroll);

        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');
    }

    render() {
        const isSearchActive = this.state.isSearchActive;
        const isQuoteActive = this.state.isQuoteActive;

        return (
            <>
                <header className="site-header header-style-1  nav-wide mobile-responsive-navigation">
                    <div className="sticky-header main-bar-wraper is-fixed">
                        <div className="main-bar bg-white p-t10">
                            <div className="container">
                                <div className="logo-header">
                                    <div className="logo-header-inner logo-header-one">
                                        <NavLink to={"./"}>
                                            <img src={this.state.logoB.default} width={28} alt="Beatriz" />
                                            {/* <img src={this.state.logoFullAlternative.default} width={256} alt="Beatriz Rolim" /> */}
                                        </NavLink>
                                    </div>
                                </div>
                                {/* ETRA Nav */}
                                <div className="extra-nav">
                                    <div className="extra-cell">
                                    <NavLink to={"#"} className="vnav-btn" onClick={this.handleQuoteToggle}><span className="fa fa-bars" /></NavLink>
                                        
                                    </div>
                                </div>
                                <div className="extra-nav">
                                    <div className="extra-cell">
                                      <div className="nav-wrapper">
                                        <div className="sl-nav">
                                          <ul>
                                            <li>
                                              <i className={`selectedLanguage sl-flag flag-${this.context.locale === undefined || this.context.locale === null ? "pt-pt" : this.context.locale}`}></i>
                                              <i className="fa fa-angle-down" aria-hidden="true"></i>
                                              <div className="triangle"></div>
                                              <ul>
                                                <li onClick={() => this.context.setLocale("pt-pt") }>
                                                  <i className="sl-flag flag-pt-pt">
                                                    <div id="portuguese"></div>
                                                  </i> 
                                                  <span className="active">
                                                    <FormattedMessage id="portugues" />
                                                  </span>
                                                </li>

                                                <li onClick={() => this.context.setLocale("en-US") }>
                                                  <i className="sl-flag flag-en-US">
                                                    <div id="english"></div>
                                                  </i> 
                                                    <span>
                                                      <FormattedMessage id="ingles" />
                                                    </span>
                                                </li>
                                              </ul>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                <div className="extra-nav header-top-info">
                                    <div className="extra-cell">
                                        <div className="wt-topbar-right clearfix top-bar">
                                            <ul className="social-bx list-inline pull-right">
                                                <li><a rel="noreferrer" target="_blank" href="https://wa.me/351910518913" className="fa fa-whatsapp" /></li>
                                                <li><a rel="noreferrer" target="_blank" href="https://www.instagram.com/beatrizrolimarquiteta" className="fa fa-instagram" /></li>
                                                <li><a rel="noreferrer" target="_blank" href="https://www.facebook.com/beatrizstudio3d" className="fa fa-facebook" /></li>
                                                <li><a rel="noreferrer" target="_blank" href="https://in.linkedin.com/company/beatrizstudio" className="fa fa-linkedin" /></li>
                                            </ul>
                                            <ul className="list-unstyled list-inline e-p-bx pull-right color-primary">
                                                {/* <li>
                                                    <span className="switcher flex flex-row">
                                                        {this.context.locale} { " " }
                                                        <select onChange={(e) => this.context.setLocale(e.target.value)}>
                                                            {languages.map(({ name, code }) => (
                                                            <option key={code} value={code}>
                                                                {name}
                                                            </option>
                                                            ))}
                                                        </select>
                                                    </span>
                                                </li> */}
                                                <li><i className="fa fa-envelope" />contato@beatrizrolim.com</li>
                                                <li><i className="fa fa-phone" />+351 910 518 913</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* ETRA Nav */}
                                <div className="extra-nav">
                                    <div className="extra-cell">
                                        <div className="dropdown share-icon-btn2">
                                            <a href="#" className="site-search-btn dropdown-toggle" data-toggle="dropdown"><i className="fa fa-share-alt" /></a>
                                            <div className="dropdown-menu bg-white">
                                                <div className="top-bar">
                                                    <ul className="social-bx list-inline">
                                                      <li><a rel="noreferrer" target="_blank" href="https://wa.me/351910518913" className="fa fa-whatsapp" /></li>
                                                      <li><a rel="noreferrer" target="_blank" href="https://www.instagram.com/beatrizrolimarquiteta" className="fa fa-instagram" /></li>
                                                      <li><a rel="noreferrer" target="_blank" href="https://www.facebook.com/beatrizstudio3d" className="fa fa-facebook" /></li>
                                                      <li><a rel="noreferrer" target="_blank" href="https://in.linkedin.com/company/beatrizstudio" className="fa fa-linkedin" /></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* STYLE SWITCHER  ======= */}
                                <div className="nav-sidebar" style={{ right: isQuoteActive ? '0px' : '-500px' }}>
                                    <NavLink to={"#"} className="vnav-close" onClick={this.handleQuoteToggle} />
                                    <div className="nav-siderbar-scroll">
                                        <div className="vertical-content-wrap">
                                            <div className="vertical-content-area vertical-centered">
                                                {/* MAIN Vav */}
                                                <div className="vertical-nav">
                                                <Navigation />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* STYLE SWITCHER END ==== */}
                            </div>
                        </div>
                    </div>
                </header>

            </>
        );
    };
};

Header.contextType = AppContext;

export default Header;