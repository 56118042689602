import { BuildingOffice2Icon, HomeModernIcon, PaintBrushIcon } from '@heroicons/react/24/solid';
import React from 'react';

var bgimg = require('./../../images/background/bg-11.webp');

class Statistics extends React.Component {
    
    render() {
        
        return (
            <>
                <div className="section-full p-t90 p-b50 overlay-wraper bg-top-center bg-parallax tm-facts-wrap" data-stellar-background-ratio="0.5" style={{ backgroundImage: "url(" + bgimg.default + ")" }}>
                        <div className="overlay-main opacity-08 bg-beige-tertiary" />
                        <div className="container ">
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="some-facts">
                                        <div className="text-blue-primary">
                                            <h2 className="font-50 text-uppercase text-blue-primary">
                                                Quem atendemos?
                                            </h2>
                                            <p className="font-18 font-weight-300" style={{ textAlign: "justify", textJustify: "inner-word" }}>Nosso público alvo é voltado para empresas e gabinetes que pretendem potencializar suas apresentações através das imagens fotorealistas
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="row some-facts-counter">
                                        <div className="col-md-4 col-sm-4 col-xs-4">
                                            <div className="wt-icon-box-wraper p-a10 t-4 text-white m-b30">
                                                <div className="icon-content text-center text-blue-primary">
                                                    {/* <div className="font-40 font-weight-600 m-b5"><span className="counter"><CountUp end={45452341} duration={5} /></span></div>
                                                    <div className="wt-separator-outer m-b20"><div className="wt-separator bg-white" /></div> */}
                                                    <div><HomeModernIcon style={{ "width": "3rem" }} /></div>
                                                    <span className="text-uppercase">Arquitetos e Designers</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-4">
                                            <div className="wt-icon-box-wraper p-a10 text-white m-b30">
                                                <div className="icon-content text-center text-blue-primary">
                                                    {/* <div className="font-40 font-weight-600 m-b5"><span className="counter"><CountUp end={5424232} duration={5} /></span></div>
                                                    <div className="wt-separator-outer m-b20"><div className="wt-separator bg-white" /></div> */}
                                                    <div><BuildingOffice2Icon style={{ "width": "3rem" }} /></div>
                                                    <span className="text-uppercase">Imobiliárias</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-4">
                                            <div className="wt-icon-box-wraper p-a10 text-white m-b30">
                                                <div className="icon-content text-center text-blue-primary">
                                                    {/* <div className="font-40 font-weight-600 m-b5"><span className="counter"><CountUp end={2915129} duration={5} /></span></div>
                                                    <div className="wt-separator-outer m-b20"><div className="wt-separator bg-white" /></div> */}
                                                    <div><PaintBrushIcon style={{ "width": "3rem" }} /></div>
                                                    <span className="text-uppercase">Gabinetes de Interior Design</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </>
        );
    }
};

export default Statistics;