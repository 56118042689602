import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { AppContext } from './components/AppContext';
import Components from './components/Components';
import Loader from "./components/Segments/Loader";
import ScrolToTop from './components/Segments/ScrolToTop';
import { messages } from "./i18n/messages";
import { LOCALES } from './i18n/locales';

const App = () => {
  const [locale, setLocaleInternal] = useState(LOCALES.PORTUGUESE);
  
  const setLocale = (locale) => {
    setLocaleInternal(locale);
    window.localStorage.setItem("locale", locale);
  }

  useEffect(() => {
    const currentLocale = window.localStorage.getItem("locale");

    if(currentLocale !== undefined && currentLocale !== null && currentLocale !== "") {
      setLocaleInternal(currentLocale);
    }
  }, []);

  return (
    <AppContext.Provider value={{ locale, setLocale }}>
      <IntlProvider
        messages={messages[locale]}
        locale={locale}
        defaultLocale={locale}>
        <div className="App">
          <Components currentLocale />
          <ScrolToTop />
          <Loader />
        </div>
      </IntlProvider>
    </AppContext.Provider>
  );
}

export default App;