import React from "react";
import axios from "axios";

class ContactUs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      message: "",
      firstCall: true
    };
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    var submitElement = document.getElementById("btnSubmit");
    var errorTip = e.target.nextElementSibling;
    var usernameElement = document.getElementsByName("username")[0];
    var emailElement = document.getElementsByName("email")[0];
    var messageElement = document.getElementsByName("message")[0];

    if(this.state.firstCall) {
      usernameElement.classList.add("error");
      emailElement.classList.add("error");
      messageElement.classList.add("error");
      this.state.firstCall = false;
    }

    if (e.target.value !== null && e.target.value !== undefined && e.target.value.trim().length > 0) {
      e.target.classList.remove("error");
      errorTip.classList.remove("d-block");
      errorTip.classList.add("d-none");
    } else {
      e.target.classList.add("error");
      errorTip.classList.remove("d-none");
      errorTip.classList.add("d-block");
    }

    if(e.target.name === "email") {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if(!e.target.value.match(validRegex)) {
        e.target.classList.add("error");
        errorTip.classList.remove("d-none");
        errorTip.classList.add("d-block");
      }
    }

    if (usernameElement.classList.contains("error") 
      || emailElement.classList.contains("error") 
      || messageElement.classList.contains("error")) {
        submitElement.classList.add("error");
        submitElement.setAttribute("disabled", "disabled");
    } else {
      submitElement.removeAttribute("disabled");
      submitElement.classList.remove("error");
    }
  };

  submitHandler = (e) => {
    e.preventDefault();

    let success = true;
    var usernameElement = document.getElementsByName("username")[0];
    var emailElement = document.getElementsByName("email")[0];
    var messageElement = document.getElementsByName("message")[0];
    var submitElement = document.getElementById("btnSubmit");
    var formMessage = document.getElementById("formMessage");

    if (usernameElement.classList.contains("error") 
      || emailElement.classList.contains("error") 
      || messageElement.classList.contains("error")) {
      success = false;
    }

    if (success) {
      submitElement.classList.add("loading");
      submitElement.setAttribute("disabled", "disabled");

      axios
        .post(`${process.env.REACT_APP_GATEWAY}/v1/contact`, {
          ws: "https://beatriz.studio",
          ci: this.state.email,
          cn: this.state.username,
          sb: "Portal: beatriz.studio - Email Recebido através do Portal",
          ms: this.state.message,
        })
        .then((response) => {
          setTimeout(() => {
            formMessage.classList.remove("opacity-0");
            formMessage.classList.add("opacity-1");
            this.resetForm();
          }, 1500);

          setTimeout(() => {
            formMessage.classList.remove("opacity-1");
            formMessage.classList.add("opacity-0");
          }, 10000);
        })
        .catch((error) => {
          console.log(error);
        }).finally(() => {
          setTimeout(() => {
            submitElement.classList.remove("loading");
          }, 1500);
        });
    }
  };

  resetForm = () => {
    this.setState({ "username": "" });
    this.setState({ "message": "" });
    this.setState({ "email": "" });
    this.setState({ "firstCall": true });
  }

  render() {
    return (
      <>
        <div className="section-full p-tb80 tm-shortcode-wrap">
          {/* LOCATION BLOCK*/}
          <div className="container">

            {/* TITLE START */}
            <div className="section-head text-left">
              <h2 className="text-uppercase font-36 text-blue-primary">Contacte-nos </h2>
              <div className="wt-separator-outer">
                <div className="wt-separator bg-blue-primary" />
              </div>
            </div>
            {/* TITLE END */}
            {/* GOOGLE MAP & CONTACT FORM */}
            <div className="section-content">
              {/* CONTACT FORM*/}
              <div className="wt-box">
                <form className="contact-form cons-contact-form" method="post" autoComplete="off" onSubmit={this.submitHandler}>
                  <div className="contact-one p-a40 p-r150">
                    
                    <div id="formMessage" className="section-content section-message opacity-0">
                        <div className="alert alert-success no-bg">
                            Entraremos em contato o mais breve possível!
                        </div>
                    </div>

                    <div className="form-group">
                      <input name="username" type="text" className="form-control" placeholder="Nome" onChange={this.changeHandler} value={ this.state.username || "" } autoComplete="off" />
                      <small className="errorTip d-none">Informe o nome corretamente</small>
                    </div>
                    <div className="form-group">
                      <input name="email" type="text" className="form-control" placeholder="Email" onChange={this.changeHandler} value={ this.state.email || "" } autoComplete="off" />
                      <small className="errorTip d-none">Informe o email corretamente</small>
                    </div>
                    <div className="form-group">
                      <textarea name="message" rows={3} className="form-control" placeholder="Messagem" value={ this.state.message || "" } onChange={this.changeHandler} autoComplete="off" />
                      <small className="errorTip d-none">Informe a mensagem corretamente</small>
                    </div>
                    <button id="btnSubmit" className="btn-default bg-blue-primary radius-no text-uppercase disabled" disabled="disabled">
                      <div className="btn-container">
                        <div className="bar"></div>
                      </div>
                      Quero ser contactado
                    </button>

                    <div className="contact-info bg-blue-tertiary text-white p-a30">
                      <div className="wt-icon-box-wraper left p-b30">
                        <div className="icon-sm">
                          <i className="iconmoon-smartphone-1" />
                        </div>
                        <div className="icon-content text-white">
                          <h5 className="m-t0 text-uppercase">Contato</h5>
                          <p>+351 910 518 913</p>
                          <p>+55 11 91430-9817</p>
                        </div>
                      </div>
                      <div className="wt-icon-box-wraper left p-b30">
                        <div className="icon-sm">
                          <i className="iconmoon-email" />
                        </div>
                        <div className="icon-content text-white">
                          <h5 className="m-t0 text-uppercase">Email</h5>
                          <p>contato@beatrizrolim.com</p>
                        </div>
                      </div>
                      <div className="wt-icon-box-wraper left">
                        <div className="icon-sm">
                          <i className="iconmoon-travel" />
                        </div>
                        <div className="icon-content text-white">
                          <h5 className="m-t0 text-uppercase">Endereço</h5>
                          <p>
                            4740-331 <span className="ml-1">Fão, Braga</span>
                            <br />
                            Portugal
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ContactUs;
