import React from "react";

class Slider extends React.Component {
  componentDidMount() {
    // function loadScript(src) {
    //   return new Promise(function (resolve, reject) {
    //     var script = document.createElement("script");
    //     script.src = src;
    //     script.addEventListener("load", function () {
    //       resolve();
    //     });
    //     script.addEventListener("error", function (e) {
    //       reject(e);
    //     });
    //     document.body.appendChild(script);
    //     document.body.removeChild(script);
    //   });
    // }

    // loadScript("./assets/js/rev-script-1.js");
  }

  render() {
    return (
      <>
        <div className="mainImage"></div>
      </>
    );
  }
}

export default Slider;
