import React from 'react';
import Footer from '../Common/Footer';
import Header from '../Common/Header';
import Blog from './../Segments/Blog';
import ClientsLogo from './../Segments/ClientsLogo';
import LatestProjects from './../Segments/LatestProjects';
import Slider from './../Segments/Slider';
import Statistics from './../Segments/Statistics';
import TeamMembers from './../Segments/TeamMembers';
import Testimonials from './../Segments/Testimonials';
import ContactUs from './ContactUs';
import Timeline from './../Segments/Timeline';

class Home extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Slider />
                    <TeamMembers />
                    <LatestProjects />
                    {/* <WhoWeAre />
                    <About /> */}
                    <Timeline />
                    <Statistics />
                    <ContactUs />
                    <Testimonials />
                    <ClientsLogo />
                </div>
                <Footer />
            </>
        );
    };
};

export default Home;