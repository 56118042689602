import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const members = [
    {
        key: 1,
        image: require('./../../images/testimonials/pic4.jpg'),
        fullname: 'Elizaveta Sergeeva',
        position: 'Interior Design',
        description: 'Eu tinha um projeto a fazer com Beatriz. Ela fez meu trabalho com muito cuidado e atenção para os detalhes. Não hesitou a fazer perguntas para esclareficar tudo. Afinal, o trabalho foi feito rápido e muito bem. Aconselho-la imenso.',
    },
    {
      key: 2,
      image: require('./../../images/testimonials/pic3.jpg'),
      fullname: 'Amanda Araújo',
      position: 'Architect',
      description: 'Estudei e trabalhei com a Beatriz, profissional super qualificada. O compromisso com o prazo e a qualidade dos projetos é um traço muito forte na Beatriz. Fora que é uma pessoa com excelentes ideias criativas.',
    },
    {
        key: 3,
        image: require('./../../images/testimonials/pic1.jpg'),
        fullname: 'Sollutie',
        position: 'Empresa de Tecnologia',
        description: 'Empresa séria de exímia qualidade, atenção desde o processo criativo a entrega. Podemos dizer que foi muito gosto esta parceria.',
    },
    {
        key: 4,
        image: require('./../../images/testimonials/pic2.jpg'),
        fullname: 'Lucas Holanda',
        position: 'Tech Lead',
        description: 'Trabalhamos juntos na empresa Aptare Tecnologia e assim acabei conhecendo os projectos de Beatriz como Arquitecta. Solicitei o projecto de meu escritório e fiquei muito satisfeito com o resultado final. Beatriz é uma pessoa capacitada, dedicada e proativa.',
    },
]

var bgimg = require('./../../images/background/ptn-1.png');

class Testimonials extends React.Component {

    render() {
        const options = {
            loop: true,
            autoplay: false,
            margin: 80,
            nav: false,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1
                },
                991: {
                    items: 2
                }
            }
        };
        return (
            <>
                <div className="section-full p-t140 bg-repeat tm-testimonial-wrap" style={{ backgroundImage: 'url(' + bgimg.default + ')' }}>
                    <div className="container">
                        <div className="section-content">
                            {/* TITLE START */}
                            {/* TITLE END */}
                            {/* TESTIMONIAL 4 START ON BACKGROUND */}
                            <div className="section-content">
                                <OwlCarousel className="owl-carousel testimonial-home" {...options}>
                                    {members.map((item, index) => (
                                        <div className="item" key={item.key}>
                                            <div className="testimonial-6">
                                                <div className="testimonial-pic-block">
                                                    <div className="testimonial-pic">
                                                        <img src={item.image.default} alt="" width={132} height={132} />
                                                    </div>
                                                </div>
                                                <div className="testimonial-text clearfix bg-white">
                                                    <div className="testimonial-detail clearfix">
                                                        <strong className="testimonial-name text-blue-primary">{item.fullname}</strong>
                                                        <span className="testimonial-position text-blue-primary p-t0">{item.position}</span>
                                                    </div>
                                                    <div className="testimonial-paragraph text-black p-t15">
                                                        <span className="fa fa-quote-left text-blue-secondary" />
                                                        <p>{item.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    ))}
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="hilite-title p-lr20 m-tb20 text-right text-uppercase bdr-gray bdr-right">
                            <strong>Clientes</strong>
                            <span className="text-black">Recomendam</span>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Testimonials;