import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { DocumentCheckIcon, HandThumbUpIcon, HomeIcon, NewspaperIcon, PaperClipIcon } from '@heroicons/react/24/solid'
import { FormattedMessage } from "react-intl";

class Timeline extends React.Component {
  render() {
    return (
      <>
        <VerticalTimeline>
          <VerticalTimelineElement className="vertical-timeline-element--work" iconStyle={{ background: "#4A5E70", color: "#fff" }} icon={<NewspaperIcon />} contentStyle={{ background: "rgb(243, 232, 220)" }}>
            <h3 className="vertical-timeline-element-title"><FormattedMessage id="page_timeline_informacoes_cliente_orcamento" /></h3>
            <p><FormattedMessage id="page_timeline_informacoes_cliente_orcamento_descricao" /></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement className="vertical-timeline-element--work" iconStyle={{ background: "#4A5E70", color: "#fff" }} icon={<HomeIcon />} contentStyle={{ background: "rgb(243, 232, 220)" }}>
            <h3 className="vertical-timeline-element-title"><FormattedMessage id="page_timeline_escopo_modelagem_3d" /></h3>
            <p><FormattedMessage id="page_timeline_escopo_modelagem_3d_descricao" /></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement className="vertical-timeline-element--work" iconStyle={{ background: "#4A5E70", color: "#fff" }} icon={<HandThumbUpIcon />} contentStyle={{ background: "rgb(243, 232, 220)" }}>
            <h3 className="vertical-timeline-element-title"><FormattedMessage id="page_timeline_imagens_previas_para_feedback" /></h3>
            <p><FormattedMessage id="page_timeline_imagens_previas_para_feedback_descricao" /></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement className="vertical-timeline-element--work"iconStyle={{ background: "#4A5E70", color: "#fff" }} icon={<PaperClipIcon />} contentStyle={{ background: "rgb(243, 232, 220)" }}>
            <h3 className="vertical-timeline-element-title"><FormattedMessage id="page_timeline_apresentacao_proposta" /></h3>
            <p><FormattedMessage id="page_timeline_apresentacao_proposta_descricao" /></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement className="vertical-timeline-element--work" iconStyle={{ background: "#4A5E70", color: "#fff" }} icon={<DocumentCheckIcon />} contentStyle={{ background: "rgb(243, 232, 220)" }}>
            <h3 className="vertical-timeline-element-title"><FormattedMessage id="page_timeline_entrega_final" /></h3>
            <p><FormattedMessage id="page_timeline_entrega_final_descricao" /></p>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </>
    );
  }
}

export default Timeline;
