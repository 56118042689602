import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FormattedMessage } from "react-intl";

var img1 = require("./../../images/our-team5/pic1.webp");

const members = [
  {
    image: require("./../../images/our-team5/pic4.webp"),
    fullname: <FormattedMessage id="page_teammembers_design_de_produto" />,
    position: "",
  },
  {
    image: require("./../../images/our-team5/pic5.webp"),
    fullname: <FormattedMessage id="page_teammembers_renderizacao_de_imagens" />,
    position: "",
  },
  {
    image: require("./../../images/our-team5/pic2.webp"),
    fullname: <FormattedMessage id="page_teammembers_concepcao_de_projeto" />,
    position: "",
  },
  {
    image: require("./../../images/our-team5/pic3.webp"),
    fullname: <FormattedMessage id="page_teammembers_modelagem_3d" />,
    position: "",
  }
];

class TeamMembers extends React.Component {
  render() {
    return (
      <>
        <div className="section-full bg-gray tm-expert-wrap">
          <div className="container-fluid">
            <div className="section-content">
              <div className="row">
                <div className={`col-md-6 col-sm-12 bg-blue-primary`}>
                  <div className="wt-left-part m-experts p-t90">
                    {/* TITLE START */}
                    <div className="section-head text-left text-white">
                      <h2 className="text-uppercase font-36">
                        <FormattedMessage id="page_teammembers_fundadora" />
                      </h2>
                      <div className="wt-separator-outer">
                        <div className="wt-separator bg-white" />
                      </div>
                    </div>
                    {/* TITLE END */}
                    <div className="wt-team-six large-pic">
                      <div className="wt-team-media wt-thum-bx">
                        <img src={img1.default} alt="" />
                      </div>
                      <div className="wt-team-info text-center p-lr10 p-tb20 text-white">
                        <h2 className="wt-team-title text-uppercase">
                          <span className="text-white font-32 font-weight-500">Beatriz Rolim</span>
                        </h2>
                        <p className="font-18"><FormattedMessage id="page_teammembers_arquiteta_e_designer_de_interiores" /></p>
                        <ul className="social-icons social-md social-square social-dark white-border has-bg">
                          <li>
                            <a target="_blank" href="https://wa.me/351910518913" className="fa fa-whatsapp" rel="noreferrer" />
                          </li>
                          <li>
                            <a target="_blank" href="https://www.instagram.com/beatrizrolimarquiteta" className="fa fa-instagram" rel="noreferrer" />
                          </li>
                          <li>
                            <a target="_blank" href="https://www.facebook.com/beatrizstudio3d" className="fa fa-facebook" rel="noreferrer" />
                          </li>
                          <li>
                            <a target="_blank" href="https://www.linkedin.com/company/beatrizstudio3d" className="fa fa-linkedin" rel="noreferrer" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="wt-right-part team-outer">
                    <div className="row">
                      {members.map((item, index) => (
                        <div className={`col-md-6 col-sm-6 col-xs-6 col-xs-100pc m-tb15`} key={index}>
                          <div className="wt-team-six bg-white">
                            <div className="wt-team-media wt-thum-bx wt-img-overlay1">
                              <img src={item.image.default} alt="" />
                              {/* <div className="overlay-bx">
                                <div className="overlay-icon">
                                  <ul className="social-icons social-square social-dark">
                                    <li>
                                      <a target="_blank" href="https://www.facebook.com" className="fa fa-facebook" rel="noreferrer" />
                                    </li>
                                    <li>
                                      <a target="_blank" href="https://www.twitter.com" className="fa fa-twitter" rel="noreferrer" />
                                    </li>
                                    <li>
                                      <a target="_blank" href="https://in.linkedin.com" className="fa fa-linkedin" rel="noreferrer" />
                                    </li>
                                    <li>
                                      <a target="_blank" href="https://www.youtube.com" className="fa fa-youtube" rel="noreferrer" />
                                    </li>
                                    <li>
                                      <a target="_blank" href="https://www.instagram.com" className="fa fa-instagram" rel="noreferrer" />
                                    </li>
                                  </ul>
                                </div>
                              </div> */}
                            </div>
                            <div className="wt-team-info text-center bg-white p-lr10 p-tb20">
                              <h5 className="wt-team-title text-uppercase m-a0">
                                <span>{item.fullname}</span>

                                {/**/}
                              </h5>
                              <p className="m-b0">{item.position}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TeamMembers;
